* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-size: 10px;
  font-family: "Roboto", sans-serif;
  line-height: 1.5;
}

* > * {
  font-size: 1.8rem;
}

input {
  font-size: 1.8rem;
}

form label {
  margin-bottom: 0;
}

h4 {
  color: inherit;
  font-size: 2.5rem;
}
body {
  color: #1c1c1b;
  overflow-x: hidden;
}

p {
  margin-bottom: 0;
}

a,
a:visited,
a:hover {
  color: #ea4a28;
}

a:hover {
  opacity: 0.8;
}

ul {
  padding: 1.5rem 3rem;
}

h1,
h2,
h3,
h5,
h6 {
  line-height: 1.1em;
  color: #3a3a3a;
  font-weight: 300;
  margin-bottom: 30px;
}

.bg-medium {
  background-color: #e1dfe2;
}

.container-fluid {
  padding: 0;
}

#app_footer a,
#app_footer a:visited {
  color: white;
}

#year {
  font-size: inherit;
}

.table {
  color: #1c1c1b;
}
.table.row,
.row {
  margin-right: 0;
  margin-left: 0;
}

.table-bordered {
  border: none;
}

.table thead th {
  border-bottom: 1px solid rgb(153, 148, 154);
}

div#cookies_notice {
  display: none;
  padding: 2rem;
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: #686369;
  color: white;
  z-index: 200;
}

div#cookies_notice > div {
  max-width: 80%;
  margin: auto;
}
div#cookies_notice a {
  color: #fff;
  font-weight: bold;
}
div#cookies_notice strong {
  font-size: inherit;
  line-height: 45px;
}

input.btn {
  color: #000;
  border: solid 1px #ea4a28;
  background-color: #fda;
  border-radius: 5px;
  padding: 1px 5px;
  vertical-align: middle;
}

.btn,
.btn:visited,
.btn:active,
.btn:focus,
.button-accept,
.button-cancel,
button {
  border: none;
  padding: 1rem 1.5rem;
  border-radius: 10px;
  color: #fff;
  font-size: 1.8rem;
  font-weight: 500;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  transition: all 0.5s ease-in-out;
  cursor: pointer;
  display: inline-block;
  transform: scale(1);
  vertical-align: middle;
}

.btn {
  background-color: #ea4a28;
}

.btn:hover,
.button-accept:hover,
.button-cancel:hover,
button:hover {
  background: #e1dfe2;
  color: #686369;
}

.btn i {
  vertical-align: inherit;
}

.btn-white {
  background: white !important;
  color: #3a3a3a !important;
}

.material-icons.md-light {
  color: #fff;
}

/* Rules for sizing the icon. */
.material-icons.md-18 {
  font-size: 18px;
}
.material-icons.md-24 {
  font-size: 24px;
}
.material-icons.md-30 {
  font-size: 30px;
}
.material-icons.md-36 {
  font-size: 36px;
}
.material-icons.md-48 {
  font-size: 48px;
}

/* Universal rules*/

.material-icons.md-stand {
  color: #ea4a28;
}
.material-icons.md-stand.md-inactive {
  color: rgb(153, 148, 154);
}

/* Rules for using icons as black on a light background. */
.material-icons.md-dark {
  color: #363336;
}
.material-icons.md-dark.md-inactive {
  color: rgb(153, 148, 154);
}

.material-icons.md-dark-neutral {
  color: #363336;
}

/* Rules for using icons as white on a dark background. */
.material-icons.md-light {
  color: rgba(255, 255, 255, 1);
}
.material-icons.md-light.md-inactive {
  color: rgba(255, 255, 255, 0.3);
}

form#login i {
  vertical-align: middle;
  margin-left: 0.5rem;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}

.input {
  padding: 0.7rem;
  filter: none;
  font-size: 1.8rem;
  border-radius: 10px;
  border: 1px solid rgb(153, 148, 154);
  outline: 0;
}

.input:focus {
  border-width: 2px;
  border-color: #ea4a28;
}

.input-mini {
  background: none;
  border: none;
  font-weight: bold;
  font-size: 1.8rem;
  padding: 0.5rem;
  text-align: center !important;
  width: 3.5rem;
}

.quantity {
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: center;
}

.change-quantity-btn {
  cursor: pointer;
}

.change-quantity-btn:hover {
  opacity: 0.9;
}

.change-quantity-btn-inactive {
  cursor: not-allowed;
  color: rgb(153, 148, 154) !important;
}

.input-mini::-webkit-outer-spin-button,
.input-mini::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.input-mini[type="number"] {
  -moz-appearance: textfield;
  /* Firefox */
}

.select_prod_class {
  position: absolute;
  left: -9999px;
}

[exclusionary="1"][data-isbase="1"] {
  position: static;
}

.label-container {
  display: flex;
  position: relative;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.label-container .material-icons {
  margin-left: 0.5rem;
}

.label-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  border: 3px solid #363336;
  border-radius: 5px;
}

[data-spanexclusionary="1"][data-spanisbase="0"],
[data-spanexclusionary="0"][data-spanisbase="1"],
[data-spanexclusionary="0"][data-spanisbase="0"] {
  position: absolute;
  left: -9999px;
}

[data-spanexclusionary="1"][data-spanisbase="1"] + span {
  padding-left: 3rem;
}

[data-custcheck="cust"] + span {
  padding-left: 3.5rem;
}

/* On mouse-over, add a grey background color */
.label-container:hover input ~ .checkmark {
  background-color: #ccc;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.label-container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.label-container .checkmark:after {
  left: 7px;
  top: 4px;
  width: 6px;
  height: 10px;
  border: solid white;
  border-width: 0 2px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.page-title {
  color: #ea4a28;
  font-family: "Roboto", sans-serif;
  font-size: 3rem;
  font-weight: 900;
  padding: 0;
  text-transform: uppercase;
  margin: 3rem 0 0 0;
}

.page-title-personal {
  margin-top: 15rem;
}

.block-offer {
  padding: 3rem 0;
}

.offer-title {
  color: #fff;
  font-size: 2.5rem;
  font-weight: normal;
  padding: 0 1.5rem;
  margin: 1.5rem 0;
  font-weight: 900;
}

.app_main {
  width: 80%;
  margin: auto;
  border-bottom-left-radius: 20px !important;
  border-bottom-right-radius: 20px !important;
}
.flash-notice {
  padding: 5px;
  font-weight: bold;
  margin: 20px;
}
.container {
  max-width: unset;
  width: 100%;
  padding: 1.5rem 0;
  margin: 4rem 0;
}
.breadcrumb {
  margin-bottom: 0;
  border-top-left-radius: 20px !important;
  border-top-right-radius: 20px !important;
  background-color: transparent;
}
.breadcrumb-item + .breadcrumb-item::before {
  content: "|";
}
.card {
  background-clip: border-box;
  background-color: #222;
  border-radius: 5px;
  color: #fff;
  min-height: 455px;
}
.card ul a {
  color: #fff;
}
.overhelp {
  border: solid 1px #ff0000;
}
.font-secondary {
  font-family: "Oswald", arial, sans-serif;
  letter-spacing: 0.11em;
  font-weight: 700 !important;
}

.ui-dialog-titlebar-close {
  display: none;
}
.field_mandatory::after {
  content: " *";
}
.validation-error {
  display: block;
  position: inherit;
  font-size: 1.4rem;
  top: auto;
  background-color: #fff;
  color: #ea4a28;
}
.table {
  overflow: hidden;
}
.table-alternative > tbody > tr:last-child > td {
  border-bottom: solid 1px #ddd;
}
.table-alternative .td_with_subtable {
  padding: 0 !important;
}

.table-hover tbody tr:hover {
  background-color: #e1dfe2;
}

.summary {
  justify-content: flex-end;
  padding-top: 3rem;
}

.summary .table {
  width: 50%;
  padding: 0 1.5rem;
}

.summary .table tbody {
  text-align: right;
}

.summary .table td,
.summary .table th {
  border: none;
}

.summary .table td strong,
.summary .table td strong span {
  font-size: 3.5rem;
}

#app_header {
  background-color: #fff;
  z-index: 2147483648;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 8.5rem;
  box-shadow: 0 0 10px 6px rgba(0, 0, 0, 0.1);
}

#login {
  display: flex;
  flex-direction: column;
  padding: 1.5rem 0 0 0;
}

#login .input {
  margin: 0.5rem 1.5rem 0.5rem 1.5rem;
  border: none;
  outline: none;
  background-color: white;
}

#logged-info {
  display: flex;
  align-items: center;
  padding: 1.5rem 1.5rem 1rem 1.5rem;
  justify-content: center;
}

#login_icon {
  margin-right: 1rem;
}
#login_bar span {
  margin-right: 5px;
}
#login_bar strong {
  font-weight: bolder;
  padding-right: 20px;
  color: #fff;
}

#user_name {
  cursor: auto;
}

#menu_row {
  height: 100%;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 3fr 5rem;
  align-items: center;
  justify-items: space-around;
  height: 100%;
  width: 100%;
}

#menu_row > div {
  padding: 1rem 1.5rem;
  padding-right: 0;
}

.log-div {
  width: 100%;
  padding: 0 0 0 0;
}

.log-div:nth-child(2) a:hover,
#login_submit:hover {
  background-color: rgba(255, 255, 255, 0.2);
}

.badge-danger {
  background-color: #363336;
}

#menu_row #top_menu {
  display: none;
  position: absolute;
  top: 7.5rem;
  right: 0;
  cursor: pointer;
  z-index: 1000;
  color: white;
}

#top_menu_content {
  float: left;
  border: none;
  border-top: none;
  border-radius: 0 0 10px 10px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  background-image: linear-gradient(
    45deg,
    rgb(239, 113, 86),
    rgba(234, 74, 40, 1)
  );
}
.dropdown-menu {
  background-color: rgb(236, 89, 58) !important;
}

#top_menu_content nav ul li:hover {
  background-color: rgba(255, 255, 255, 0.2);
}

#top_menu_content nav ul li a,
.button_login_submit {
  color: white !important;
  padding: 1rem 1.5rem;
  font-size: 1.8rem;
  border-bottom: rgba(255, 255, 255, 0.3) solid 1px;
  white-space: nowrap;
}
.button_login_submit {
  display: block;
}

#p-logo {
  justify-self: left;
}

#p-logo img {
  max-height: 5.7rem;
}

#p-slo p {
  color: #686369;
  font-family: "La Belle Aurore", cursive;
  font-size: 3rem;
  line-height: 1;
}

#menu_bar {
  height: 100%;
  display: flex;
  align-items: center;
}

footer {
  margin-top: 25rem;
}

footer hr {
  border-color: #e1dfe2;
}

#app_breadcrumb {
  margin: 10rem auto 0 auto;
  width: 80%;
  text-align: left;
}
#app_footer {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  color: white;
  font-size: 1.5rem;
}

.box_footer {
  position: relative;
  height: 350px;
}

.box_footer > * {
  width: 100%;
}

.box_footer_container {
  width: 70%;
  position: absolute;
  left: 0;
  padding: 2.5rem 4rem;
}

.box_footer a,
.box_footer p,
.box_footer li {
  font-size: 1.5rem;
  line-height: 1.6;
}

.box_footer hr {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}
#box_footer_1 {
  background-color: rgb(39, 55, 70);
  background-color: rgb(153, 148, 154);
}

#box_footer_1 .box_footer_container {
  left: 30%;
}

#box_footer_2 {
  background-color: rgb(247, 40, 0);
  background-color: #686369;
}

#box_footer_3 {
  background-color: #36815b;
  background-color: rgb(153, 148, 154);
}

#box_footer_2 .box_footer_container,
#box_footer_3 .box_footer_container {
  left: 15%;
}

#box_footer_4 {
  background-color: #686369;
}

#box_footer_4 .box_footer_content > p {
  text-align: center;
}

.box_footer_title,
.box_footer_element {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 1.5rem;
}

.box_footer_title span,
.box_footer_title img,
.box_footer_element span,
.box_footer_element img {
  display: block;
  margin-right: 0.5rem;
}

.box_footer_title h4 {
  margin: 0;
  font-weight: bolder;
  font-size: 1.5rem;
}

.box_footer_title img,
.box_footer_element img {
  max-height: 2.5rem;
}

.box_footer_element img {
  margin-right: 1rem;
}

.box_footer_links,
.box_footer_content {
  margin-top: 0.5rem;
}

#menu_button {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

#menu_button,
#menu_button:visited {
  color: white;
  width: 100%;
}

#menu_button:hover {
  text-decoration: none;
}

#menu_button i {
  margin-right: 1.5rem;
}
#buttons_down {
  margin-bottom: 20px;
}
#jquery-ui-dialog-box {
  display: none;
}
#app_footer ul {
  padding: 1.5rem;
}

/*Widgets*/

.ui-widget.ui-widget-content {
  border: none;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.ui-dialog-titlebar {
  color: #fff;
  font-weight: bold;
}

.separator {
  margin: 6rem 0;
}
#loading {
  position: absolute;
  color: #f00;
  display: none;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
}
/* loading layer >> */
#loading-txt {
  margin-top: 100px;
  position: fixed;
  left: calc(50% - 76px);
  top: calc(50% - 38px);
}
#loading-icon {
  width: 76px;
  height: 76px;
  position: fixed;
  left: calc(50% - 38px);
  top: calc(50% - 38px);
  -webkit-animation: data-spinner 1s linear infinite;
  -moz-animation: data-spinner 1s linear infinite;
  animation: data-spinner 1s linear infinite;
  color: #ea4a28;
}
#loading-icon i {
  -moz-transform: scale(-1, 1);
  -webkit-transform: scale(-1, 1);
  -o-transform: scale(-1, 1);
  -ms-transform: scale(-1, 1);
  transform: scale(-1, 1);
  font-size: 76px;
}
.product {
  border-radius: 20px;
  padding: 1em;
  margin-bottom: 1em;
}
.product p {
  margin-bottom: 0.5em;
}
.product ul,
.product_summary p:last-child {
  margin-bottom: 0;
}
.product_summary {
  padding: 1em;
  margin-bottom: 1em;
  border-radius: 20px;
}

.product_summary p {
  margin-bottom: 0.5rem;
}
.steps-wrapper li.active {
  font-weight: bold;
}
.text-center-payment {
  font-weight: bold;
}
#overlay-text {
  font-size: 2em;
  text-align: center;
  top: 50%;
  left: 50%;
  position: absolute;
  transform: translateX(-50%) translateY(-50%);
  font-weight: bold;
  color: rgb(234, 74, 40);
}

@-moz-keyframes data-spinner {
  100% {
    -moz-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-webkit-keyframes data-spinner {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes data-spinner {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@media all and (max-width: 768px) {
  .modal-content {
    width: 100% !important;
  }
}

/*styles for modal images*/
.imgModal {
  border-radius: 20px;
  cursor: pointer;
  transition: 0.3s;
}

.imgModal:hover {
  opacity: 0.7;
}

.modal {
  display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  padding-top: 100px; /* Location of the box */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.6); /* Black w/ opacity */
}

.modal-content {
  margin: auto;
  display: block;
  max-width: 70%;
  border-radius: 15px;
}

#caption {
  margin: auto;
  display: block;
  max-width: 1200px;
  text-align: center;
  color: #ccc;
  padding: 10px 0;
  height: 150px;
}

.modal-content,
#caption {
  animation-name: zoom;
  animation-duration: 0.6s;
}

@keyframes zoom {
  from {
    transform: scale(0);
  }
  to {
    transform: scale(1);
  }
}

/* Configurator */

#form_configurator .row div.col-sm-6:first-child select {
  width: auto;
}

.form-config-top-nav {
  display: grid;
  column-gap: 1rem;
  row-gap: 1rem;
  grid-template-columns: auto auto auto 35px;
  grid-template-areas: "a b c d";
  justify-items: flex-start;
  align-items: center;
}

#configurator-name-div {
  grid-area: a;
}

#save-div {
  grid-area: b;
}

#save-div-config {
  grid-area: c;
}

#help_icon {
  grid-area: d;
}

.form-config-top-nav > div {
  width: 100%;
}

.navbar-nav {
  padding: 0;
}

.navbar-nav li:last-child a {
  border-bottom: none !important;
}

#configurator_name {
  display: block;
  width: 100%;
}
.products_options {
  margin-bottom: 0;
}
.products_options > tbody > tr:first-child td {
  border-top: 0;
}
.products_options tbody tr:hover {
  color: inherit;
  background-color: inherit;
}

.table-responsive {
  padding: 0;
}

table#products td label span {
  cursor: pointer;
}

.col-md-1 {
  text-align: right;
}

.col-sm-6,
.col-sm-12 {
  padding-right: 0;
  padding-left: 0;
}

.tooltip_templates {
  display: none;
}

.tooltipster-noir-customized {
  max-width: 70%;
  margin: auto;
}

.tooltipster-noir-customized .tooltipster-content {
  padding: 4rem;
}

.tooltipster-noir-customized .tooltipster-content h5 {
  font-size: inherit;
  font-weight: bolder;
}

.tooltipster-noir-customized .tooltipster-content ul {
  margin-left: 1rem;
}
.tooltipster-noir-customized .tooltipster-content ul li {
  margin: 10px 0 10px 0;
  color: #686369;
}

.tooltipster-question {
  cursor: pointer;
}

#help_icon {
  cursor: pointer;
  justify-self: end;
}

.breadcrumb {
  padding: 1.5rem 0;
}

.help-badge {
  display: flex;
  padding: 1rem 0;
  align-items: center;
}

.help-badge * {
  margin-right: 1rem;
}

.w-prod-photo img {
  display: none;
}

/* Contractor form */

#summary .offer-title-box {
  margin-top: 1.5rem;
}

.table-contractor-form {
  padding: 3rem 0;
}

.table-contractor-form td {
  border-top: none;
}

.table-contractor-form input,
.table-contractor-form select {
  width: 50%;
  padding: 0.7rem;
  border: rgb(153, 148, 154) 1px solid;
  border-radius: 10px;
  color: #1c1c1b;
}

.table-contractor-form label {
  padding: 0.7rem;
  margin: 0;
}

.form-control {
  font-size: 1.8rem;
}

.alert {
  border-color: none;
  border: none;
  border-radius: 10px;
  margin-bottom: 3rem;
}

.alert-icon {
  vertical-align: middle;
  margin-right: 5px;
}

.alert-info {
  background-color: #e1dfe2;
  color: #363336;
}

.alert-info hr {
  border-top-color: rgb(153, 148, 154);
}

.form-control:focus {
  background-color: #fff;

  border-width: 2px;
  outline: 0;
  box-shadow: unset;
}

.table-contractor-form > tbody > tr:last-child > td {
  border-bottom: none;
}

#login_client td,
#register_client td {
  border-top: none;
}

#login_client_go,
#register_client_go,
.btn-personal {
  margin: 3rem 0;
}

.legal p {
  padding: 1rem 0;
}

/*
    Selector (Configuraciones guardadas)
*/

#selector_img {
  display: none;
}

#configurations_list {
  padding: 1.5rem 0;
}

#configurations_list .list-element {
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

.list-group-item {
  padding-left: 0;
  padding-right: 0;
}

/*Area Personal*/

#aside-menu {
  padding: 0;
  border-radius: 10px;
  margin-right: 1.5rem;
  max-width: 250px;
  background-image: linear-gradient(
    45deg,
    rgba(234, 74, 40, 0.8),
    rgba(234, 74, 40, 1)
  );
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

#aside-menu .list-group-item {
  background-color: transparent;
  padding: 1rem 1.5rem;
  border: unset;
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
  color: #fff;
}

#aside-menu .list-group-item:hover {
  background-color: rgba(255, 255, 255, 0.2);
}

#aside-menu .list-group-item.active {
  background-color: rgba(255, 255, 255, 0.2);
}

#aside-menu .list-group:last-child {
  border-bottom: none;
}

#personal #expand-more-right {
  display: none;
}

#content-personal {
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  flex: 1;
  padding: 3rem 1.5rem;
}

#personal {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.personal-form-div {
  width: 100%;
}

.personal-form-table {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.personal-form-table > div {
  display: flex;
  flex-direction: column;
  width: 50%;
  padding-bottom: 1.5rem;
}

.btn-personal {
  max-width: 200px;
  align-self: center;
}

.personal-form-div .form-control {
  width: auto;
}

#searchdiv form {
  width: 100%;
  margin-bottom: 3rem;
}

#searchdiv {
  display: flex;
  justify-content: end;
}

#searchdiv .form-group {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

#searchdiv .form-group span {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 0.5rem;
}

#home-content {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

#home-content div {
  margin-top: 6rem;
}

.tbl_personal {
  margin-bottom: 3rem;
}

.tbl_personal td,
.tbl_personal th {
  font-size: 0.85em;
}

.actions-cell {
  display: flex;
  flex-direction: row;
}
.actions-cell > a,
.actions-cell > i {
  margin-right: 1rem;
}

.inactive-icon {
  cursor: not-allowed;
}

.header-xs {
  display: none;
}

#expand-more-left {
  display: none;
}

#contract_view p {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

#contract_view h4 {
  margin-bottom: 3rem;
  color: #ea4a28;
}

/*show_contract*/

#pdf_iframe,
#iframecontainer iframe {
  border: 1px rgba(255, 255, 255, 0.2);
  border-radius: 15px;
}

.product_separator {
  border-radius: 0;
  margin-bottom: 0;
}

.products_show > *:last-child {
  border-bottom: none;
}

/*Payment*/

#payment_recurrent_div_1 img {
  width: 100%;
  height: auto;
}

.buttons-payment {
  background-color: white;
  display: flex;
  justify-content: center;
}

.buttons-payment #div-next-step {
  width: auto;
}

#step-4 > div {
  margin-bottom: 10rem;
}

#table_payment_summary td {
  border-top: none;
}

/*Pending Payment*/

/* Privacy Policy */

.private-policy {
  padding: 1.5rem;
}

.private-policy h4 {
  margin: 3rem 0;
}

.private-policy ol {
  list-style-position: inside;
}

.private-policy ol li {
  padding: 1rem;
}

/*Recover password*/
.login-register-table td {
  border-top: none;
}

/*Marketing pages*/
.marketing_left_img_in_text {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-bottom: 1.5rem;
}

.marketing_left_img_in_text img {
  max-width: 200px;
  height: auto;
  margin-right: 3rem;
}

.block_marketing {
  color: white;
  padding: 3rem;
  border-radius: 15px;
}
