@media (max-width: 1812px) {
  /* 
      Footer
  */

  .box_footer a,
  .box_footer p,
  .box_footer li,
  .box_footer_element {
    font-size: 1.4rem;
  }

  .summary .table {
    width: 55%;
  }
}

@media (max-width: 1648px) {
  /* 
      Footer
  */

  .box_footer a,
  .box_footer p,
  .box_footer li,
  .box_footer_element {
    font-size: 1.3rem;
  }
}

@media (max-width: 1650px) {
  * > * {
    font-size: 1.7rem;
  }

  #top_menu_content nav ul li a,
  .input,
  .button_login_submit {
    font-size: 1.7rem;
  }

  .btn,
  .btn:visited,
  .btn:active,
  .btn:focus {
    font-size: 1.7rem;
  }

  .page-title {
    font-size: 2.8rem;
  }

  .offer-title {
    font-size: 2.2rem;
  }

  .summary .table td strong,
  .summary .table td strong span {
    font-size: 3.3rem;
  }
}

@media (max-width: 1577px) {
  .summary .table {
    width: 65%;
  }
  /* 
        Footer
  */

  .box_footer_container {
    width: 80%;
  }

  #box_footer_1 .box_footer_container {
    left: 20%;
  }

  #box_footer_2 .box_footer_container,
  #box_footer_3 .box_footer_container {
    left: 10%;
  }

  .login-register-table td {
    display: block;
    width: 100%;
    padding-left: 0;
    padding-right: 0;
  }
}

@media (max-width: 1499px) and (min-width: 1251px) {
  /*Area Personal*/
  #personal {
    flex-direction: column;
  }

  #personal #aside-menu {
    max-width: 100%;
    margin-right: 0;
    margin-bottom: 1.5rem;
  }

  #personal #aside-menu .list-group {
    display: flex;
    flex-direction: row;

    justify-content: center;
    align-items: center;
  }

  #personal #aside-menu .list-group a {
    width: auto;
    text-align: center;
    border-bottom: none;
  }

  #personal #aside-menu .list-group a:hover {
    background-color: transparent;
    opacity: 0.5;
  }

  #personal #aside-menu .list-group a.active {
    background-color: transparent;
    font-weight: bold;
  }
}

@media (max-width: 1380px) {
  .summary .table {
    width: 70%;
  }

  /* 
     Footer
  */

  .box_footer_container {
    width: 100%;
  }

  #box_footer_1 .box_footer_container {
    left: 0;
  }

  #box_footer_2 .box_footer_container,
  #box_footer_3 .box_footer_container {
    left: 0;
  }

  /*Show Contract*/
  #contract_show_container {
    flex-direction: column;
  }

  #contract_show_container > div:first-child {
    margin-bottom: 4rem;
  }

  #contract_show_container > div {
    max-width: 100%;
  }
}

@media (max-width: 1335px) {
  .col-md-5 {
    flex: 0 0 35%;
    max-width: 35%;
  }

  .col-md-1 {
    flex: 0 0 15%;
    max-width: 15%;
  }

  .col-md-6 {
    max-width: unset;
  }

  .table-contractor-form input,
  .table-contractor-form select {
    width: 70%;
  }
}

@media (max-width: 1250px) {
  /*Area Personal*/

  #personal #aside-menu {
    max-width: 30px;
    position: absolute;
    left: 0;
    top: 190px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    cursor: pointer;
    z-index: 100;
  }

  #personal #aside-menu .list-group a {
    visibility: hidden;
  }

  #personal #expand-more-right {
    display: block;
    position: absolute;
    bottom: 50%;
  }

  #aside-menu .list-group-item {
    margin-left: 3rem;
  }

  #aside-menu .list-group-item-action {
    width: auto;
  }
}

@media (max-width: 1135px) {
  /*Area Personal*/

  .page-title-personal {
    margin-top: 10rem;
  }

  #content-personal {
    border: none;
  }

  .header-xs {
    display: inline;
  }

  .personal-container {
    margin-top: 0;
  }

  #personal #aside-menu {
    top: 140px;
  }

  .tbl_personal,
  .tbl_personal thead,
  .tbl_personal tbody,
  .tbl_personal th,
  .tbl_personal td,
  .tbl_personal tr {
    display: block;
    width: 100%;
  }

  .tbl_personal thead {
    display: none;
  }

  .tbl_personal tr {
    background: #fff;
    border-radius: 2px;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
    display: inline-block;
    margin-bottom: 3rem;
    padding: 1.5rem;
    width: 100%;
  }

  .tbl_personal tbody {
    padding: 1rem;
  }

  .tbl_personal td {
    border: none;
  }

  .tbl_personal td p {
    display: inline;
  }
}

@media (max-width: 1103px) {
  .summary .table {
    width: 100%;
  }
  /* 
       Footer
   */
  .box_footer a,
  .box_footer p,
  .box_footer li,
  .box_footer_element {
    font-size: 1.2rem;
  }

  /*Payment*/

  .config-mq {
    display: none;
  }
}

@media (max-width: 1060px) {
  .col-md-5 {
    flex: 0 0 35%;
    max-width: 35%;
  }

  .col-md-1 {
    flex: 0 0 15%;
    max-width: 15%;
  }
}

@media (max-width: 1025px) {
  /* 
      Header
  */

  .td_with_subtable tr td:first-child {
    display: none;
  }

  #app_header {
    height: 7rem;
  }

  #menu_row {
    grid-template-columns: auto auto 5rem;
  }

  #menu_bar #menu_button i {
    font-size: 3rem;
  }

  #menu_row #top_menu {
    top: 6rem;
  }

  #p-logo img {
    height: 4.5rem;
  }

  #p-slo {
    justify-self: start;
  }

  #p-slo p {
    font-size: 2.5rem;
  }

  .form-config-top-nav {
    grid-template-columns: auto auto 35px;
    grid-template-rows: auto auto;
    grid-template-areas:
      "a b d"
      "c . .";
  }

  #save-div-config {
    grid-row: 2;
  }

  /*
    Contractor
  */

  #products_summary thead tr th:first-child,
  #products_summary tbody tr td:first-child {
    flex: 0 0 49%;
    max-width: 49%;
  }

  #products_summary thead tr th:nth-child(2),
  #products_summary tbody tr td:nth-child(2),
  #products_summary thead tr th:nth-child(3),
  #products_summary tbody tr td:nth-child(3),
  #products_summary thead tr th:nth-child(4),
  #products_summary tbody tr td:nth-child(4) {
    flex: 0 0 17%;
    max-width: 17%;
  }

  #products_summary thead tr th:nth-child(2),
  #products_summary tbody tr td:nth-child(2) {
    text-align: center;
  }

  .products_options {
    height: 100%;
  }

  .products_options tbody tr td:first-child {
    display: none;
  }

  /* 
        Footer
  */

  #app_footer {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto auto;
  }

  .box_footer {
    height: 300px;
  }

  #box_footer_1,
  #box_footer_2 {
    height: 260px;
  }

  /*Marketing*/

  .marketing_left_img_in_text img {
    max-width: 120px;
  }
}

@media (orientation: landscape) and (max-width: 840px) {
  /* 
      Header
  */
  #top_menu_content nav ul li a,
  .button_login_submit {
    padding: 0.5rem 1.5rem;
  }

  #logged-info {
    padding-top: 1rem;
    padding-bottom: 0.5rem;
  }

  #login .input {
    margin-top: 0.2rem;
    margin-bottom: 0.2rem;
  }

  #app_breadcrumb {
    margin-top: 7rem;
  }
}

@media (max-width: 850px) {
  #app_breadcrumb {
    width: 100%;
  }

  #app_breadcrumb,
  .page-title,
  .form-config-top-nav {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }

  #messages {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }

  .form-config-top-nav {
    grid-template-columns: auto 35px;
    grid-template-rows: auto auto;
    grid-template-areas:
      "a d"
      "c . ";
    column-gap: 0;
    row-gap: 0.5rem;
  }

  #save-div-config select {
    font-size: 1.4rem;
    padding: 1rem 0;
    max-width: 285px;
    margin-top: 0.5rem;
  }

  #configurator_name {
    display: inline;
  }

  .container {
    margin-top: 0;
    margin-bottom: 0;
  }

  /*
        Configurator
  */

  .app_main {
    width: 100%;
    padding: 0;
  }

  table,
  thead,
  tbody,
  th,
  td,
  tr {
    display: block;
    width: 100%;
  }

  .table-responsive td {
    padding: 1rem 1.5rem;
  }

  .col-md-1 {
    text-align: left;
  }

  .summary {
    position: fixed;
    bottom: 55px;
    padding-top: 0;
    box-shadow: 0 0 10px 6px rgba(0, 0, 0, 0.1);
    background-color: #f6f5f6;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    width: 100%;
    z-index: 100;
  }

  .summary .table {
    margin-bottom: 0;
  }

  .summary .table tr {
    display: flex;
    height: auto;
  }

  .summary .table th,
  .summary .table td {
    padding: 0;
    text-align: center;
  }

  .summary .table th {
    font-weight: normal;
    font-size: 1.4rem;
    padding-top: 1rem;
    text-align: center;
  }

  #buttons_down {
    position: fixed;
    bottom: 0;
    background-color: #f6f5f6;
    margin: 0;
    width: 100%;
    z-index: 100;
  }

  #buttons_down .row {
    justify-content: center;
  }

  #buttons_down .text-left {
    order: 1;
  }
  #buttons_down .text-right {
    order: 3;
  }
  #save-div {
    order: 2;
  }

  #buttons_down .col-sm-6 {
    max-width: unset;
    flex: unset;
    width: auto;
    padding: 0;
  }

  #buttons_down .btn {
    margin: 0.5rem 0.25rem;
    padding: 1rem;
  }

  #form {
    padding-bottom: 0;
  }

  footer {
    margin-top: 10rem;
  }

  .tooltipster-noir-customized {
    max-width: 90%;
    margin: auto;
    top: 0 !important;
  }

  /*
    Contractor
  */

  #summary .row,
  #selector_content .row {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }

  #summary .summary {
    background-image: unset;
    box-shadow: unset;
    border-top-left-radius: unset;
    border-top-right-radius: unset;
  }

  #summary .summary table {
    padding: 1.5rem 0;
  }

  #summary .summary {
    position: static;
  }

  /*Area Personal*/

  #personal #aside-menu {
    max-width: 15px;
  }

  #personal #expand-more-right {
    font-size: 15px;
  }

  #tbl_contract_legend td,
  #tbl_contract_legend th {
    border: none;
  }

  #content-personal,
  .page-title-personal,
  .messages-personal {
    padding-left: 3rem !important;
  }

  #content-personal .row > div {
    padding-left: 0;
    padding-right: 0;
  }

  /*show_contract*/

  #pdf-btn-cont {
    margin: 0 1.5rem;
  }

  /*Payment*/
  .table_summary_pending_payment thead,
  #pruducts_summary thead {
    display: none;
  }

  .table_summary_pending_payment .row,
  #pruducts_summary thead .row {
    padding-top: 1.5rem;
  }

  .value-can::before {
    content: "Cantidad \a";
    white-space: pre;
    font-weight: 600;
  }

  .value-uni::before {
    content: "Pago Único Inicial \a";
    white-space: pre;
    font-weight: 600;
  }

  .value-men::before {
    content: "Pago Mensual \a";
    white-space: pre;
    font-weight: 600;
  }

  #pending_payment_tfoot tr td:nth-child(2) {
    display: none;
  }

  #pending_payment_tfoot .empty,
  #pruducts_summary_tfoot .empty {
    display: none;
  }

  #pending_payment_tfoot tr:first-child td {
    padding-top: 1.5rem;
    margin-top: 3rem;
    border-top: rgb(153, 148, 154) 1px solid;
  }

  #pending_payment_tfoot,
  #pruducts_summary_tfoot {
    display: flex;
    flex-direction: column;
  }

  #pending_payment_tfoot tr,
  #pruducts_summary_tfoot tr {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
  }

  #pending_payment_tfoot td,
  #pruducts_summary_tfoot td {
    text-align: center !important;
  }

  #pruducts_summary_tfoot td {
    border: none;
  }
  #pending_payment_tfoot td:first-child {
    text-align: left !important;
  }

  #pay-status {
    flex-direction: column !important;
    padding-top: 1.5rem;
    margin-top: 3rem;
    border-top: rgb(153, 148, 154) 1px solid;
  }

  #pay-status td:first-child {
    text-decoration: underline;
    text-align: center !important;
  }

  #pay-status .pt-4 {
    text-align: left !important;
  }

  #step-4 h4 {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }

  /*Marketing*/

  .block_marketing {
    border-radius: 0;
    margin-top: 2.5rem;
  }

  #info h4 {
    padding: 0 1.5rem;
  }

  .modal-content {
    max-width: 95%;
  }
}

@media (max-width: 786px) {
  .page-title {
    margin: 0;
  }
  /*
       Configurator
   */

  thead tr {
    position: absolute;
    top: -9999px;
    left: -9999px;
  }

  /* Config Table */

  .table td,
  table th {
    border: none !important;
  }

  .table .row {
    display: grid;
    grid-template-columns: 33.3% 33.3% 33.3%;
    grid-template-rows: auto auto;
    border-top: solid 1px #e1dfe2;
    border-bottom: solid 1px #e1dfe2;
    border-collapse: collapse;
  }

  .table .row > *:first-child {
    grid-column: 1 / span 3;
  }

  .table br {
    display: none;
  }

  .col-md-5,
  .col-md-1 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .p_inicial {
    grid-row: 2;
    grid-column: 1;
  }

  .p_mensual {
    grid-row: 2;
    grid-column: 2;
  }

  .p_inicial::before {
    content: "Inicial \a";
    white-space: pre;
    font-weight: 600;
  }

  .p_mensual::before {
    content: "Mensual \a";
    white-space: pre;
    font-weight: 600;
  }

  .td_with_subtable {
    grid-column: 3;
  }

  .td_with_subtable tr td:first-child {
    display: none;
  }

  .td_with_subtable table,
  .td_with_subtable table,
  tbody,
  .td_with_subtable table,
  tbody tr,
  .td_with_subtable table,
  tbody tr td {
    height: 100%;
  }

  .change-quantity-btn {
    margin: auto 0;
  }

  .summary th strong {
    font-size: 1.4rem;
  }

  /*
    Contract
  */

  #products_summary tbody tr td:first-child {
    flex: 0 0 100%;
    max-width: 100%;
  }

  #products_summary tbody tr td:nth-child(2),
  #products_summary tbody tr td:nth-child(3),
  #products_summary tbody tr td:nth-child(4) {
    flex: unset;
    max-width: unset;
    text-align: left !important;
  }

  .products_options label:before {
    content: "Cantidad \a";
    white-space: pre;
    font-weight: 600;
  }

  .table-contractor-form {
    padding-top: 0;
    margin: 0 0 !important;
  }

  .table-contractor-form thead tr {
    position: static;
    top: unset;
    left: unset;
  }

  .table-contractor-form .row {
    border: none;
    padding-left: 0 !important;
    padding-right: 0 !important;
    grid-template-columns: 1fr;
    grid-template-rows: auto auto;
  }

  .table-contractor-form input,
  .table-contractor-form select {
    width: 100%;
  }

  .table-contractor-form label {
    padding-left: 0;
    padding-right: 0;
  }

  .table-contractor-form .row td,
  .table-contractor-form tr td {
    text-align: left !important;
  }

  .table-contractor-form td {
    padding: 0;
  }

  .table-contractor-form .alert {
    padding-left: 0;
    padding-right: 0;
  }

  #summary .label-container {
    margin-bottom: 3rem;
  }

  /*Payment*/
  .steps-wrapper li::after {
    height: 8px;
    top: 10px;
  }

  .steps-wrapper li::before {
    width: 30px;
    height: 30px !important;
  }

  .steps-wrapper li.active.success:before {
    background-size: 16px !important;
  }

  .steps-wrapper li.active.failure:before {
    background-size: 16px !important;
  }

  .table_summary_pending_payment .row {
    border: none;
  }

  #pending_payment_tfoot tr:first-child td {
    border-top: rgb(153, 148, 154) 1px solid !important;
  }

  /*Personal Area*/

  .page-title-personal {
    margin-top: 10rem;
  }

  #expand-more-left-div {
    position: fixed;
    top: 0px;
    height: 100vh;
    width: 100vw;
    background-color: rgba(0, 0, 0, 0.19);
    z-index: 50;
  }

  #expand-more-left {
    display: none;
    position: absolute;
    color: rgb(52, 58, 64);
    top: 150px;
    left: 200px;
  }
}

@media (max-width: 740px) {
  * > * {
    font-size: 1.6rem;
  }

  #top_menu_content nav ul li a,
  .input,
  .button_login_submit {
    font-size: 1.6rem;
  }

  .btn,
  .btn:visited,
  .btn:active,
  .btn:focus {
    font-size: 1.6rem;
  }

  .page-title {
    font-size: 2.5rem;
  }

  .offer-title {
    font-size: 2rem;
  }

  .summary .table td strong,
  .summary .table td strong span {
    font-size: 3rem;
  }

  .summary th {
    line-height: 1.2;
  }

  /*
    Selector (Configuraciones guardadas)
  */

  .list-element,
  .list-group-item .row {
    flex-wrap: nowrap;
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .selector-icons {
    white-space: nowrap;
  }

  .list-unstyled {
    padding-right: 0;
  }
}

@media (max-width: 602px) {
  /* 
    Footer
  */

  #app_footer {
    grid-template-columns: 1fr;
    grid-template-rows: auto auto auto auto;
  }

  .box_footer_title {
    justify-content: center;
  }

  #box_footer_1,
  #box_footer_2,
  #box_footer_3 {
    height: 260px;
  }

  #box_footer_4 {
    height: 325px;
  }

  .box_footer a,
  .box_footer p,
  .box_footer li,
  .box_footer_element {
    font-size: 1.4rem;
  }

  /*Area personal*/

  .personal-form-table > div {
    width: 100%;
  }
}

@media (max-width: 575px) {
  /* 
      Header
  */
  #p-slo p {
    font-size: 2rem;
  }

  .summary th strong {
    font-size: 1.2rem;
  }

  .summary .table td strong,
  .summary .table td strong span {
    font-size: 2.5rem;
  }

  #summary .table-contractor-form tr {
    margin: 1rem 0;
  }

  .table-contractor-form tr td:nth-child(2) {
    margin-left: 1rem;
    margin-right: 1rem;
    width: auto;
  }

  /*Payment*/
  .table_summary_pending_payment {
    padding: 0;
  }

  #pending_payment_tfoot strong,
  #pending_payment_tfoot strong span,
  #pruducts_summary_tfoot strong,
  #pruducts_summary_tfoot span {
    font-size: 14px;
  }

  .steps-wrapper li {
    font-size: 12px;
  }
}

@media (max-width: 527px) {
  /*Header*/

  #app_header {
    height: 10rem;
  }

  #app_breadcrumb {
    margin-top: 10rem;
  }

  #menu_row {
    grid-template-columns: auto 5rem;
    grid-template-rows: auto auto;
    justify-content: space-between;
  }

  #menu_row > div {
    padding-top: 0.8rem;
    padding-bottom: 0.8rem;
  }

  #p-slo {
    grid-column: 1 / span 2;
    grid-row: 2;
    justify-self: center;
  }

  #p-slo p {
    font-size: 2rem;
  }

  /*Personal Area*/

  .page-title-personal {
    margin-top: 13rem;
  }

  #personal #aside-menu {
    top: 155px;
  }

  #personal #aside-menu {
    top: 100px;
    border-top-right-radius: 0;
  }

  #searchdiv > div {
    width: 100%;
  }

  .marketing_left_img_in_text {
    flex-direction: column;
  }

  .marketing_left_img_in_text img {
    margin-bottom: 1.5rem;
  }
}
